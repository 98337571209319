import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import PostItem from "components/resource-list"

const StyledContainer = styled(Container)`
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
`

const StyledCaseStudies = styled.div`
	padding: 28px 0;

	${breakpoint.medium`
		padding: 54px 0;
	`}
`

const CaseStudies = props => {
	const data = useStaticQuery(graphql`
    query {
        allWpCaseStudy (
			filter: {caseStudies: {publish: {eq: true}}}
			sort: {fields: date, order: ASC}
			limit: 4
		  ) {
        nodes {
          id
          title
		  slug
		  dateGmt
		  modifiedGmt
          caseStudies {
			existingSlug
			product
			description
			photo {
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData
					}
				}
			}
          }
        }
      }
    }
  `)
	return (
		<StyledCaseStudies>
			<StyledContainer>
				<div className="fullList">
					{data.allWpCaseStudy.nodes.map((post) => (
						<PostItem
							resourceType="case-study"
							key={post.id}
							link={post.caseStudies.existingSlug}
							title={post.title}
							description={post.caseStudies.description}
							product={post.caseStudies.product}
							image={
								post.caseStudies.photo.localFile
									? post.caseStudies.photo.localFile
									: false
							}
							publicURL={
								post.caseStudies.photo.localFile.publicURL
									? post.caseStudies.photo.localFile.publicURL
									: false
							}
							dateGmt={post.dateGmt}
							modifiedGmt={post.modifiedGmt}
							linkText="View Case Study"
							newWindow="_blank"
							relRef="noopener noreferrer"
						/>
					))}
				</div>
			</StyledContainer>
		</StyledCaseStudies>
	)
}


export default CaseStudies