import React from "react"

// Components
import DownloadsSection from "components/downloads-sections"
import DownloadsList from "components/downloads-lists"

// Page Sections
import List from "./sti-list-case-studies"

const CaseStudies = props => {
  return (
    <DownloadsSection
      id="sti-casestudies"
      viewAllowed={props.viewAllowed}
      data-filter="sexual-health" data-filter-type="devices" data-section="casestudies"
    >
			<DownloadsList>
				<div className="block__content">
					<h3>Case Studies</h3>
				</div>
        <List />
      </DownloadsList>
    </DownloadsSection>
  )
}

export default CaseStudies
